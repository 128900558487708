@font-face {
  font-family: 'NotoSansCJKKR';
  src: url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
  unicode-range: 'U+1100–11FF';
}

@font-face {
  font-family: 'Montserrat';
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
}

button,
input,
li,
table,
a,
h1,
h2,
h3,
h4,
h5,
span,
p,
div,
table,
tr,
td,
th,
head,
select,
article {
  font-family: 'Montserrat', 'NotoSansCJKKR';
  letter-spacing: -0.8px;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  font-size: 16px;
  min-width: 1920px;
}

body {
  background-color: #f5f5f5 !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}
